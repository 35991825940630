import { AmplifyUser } from '@aws-amplify/ui';
import { Component, Inject, inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TUI_IS_MOBILE } from '@taiga-ui/cdk';
import { TuiCountryIsoCode } from '@taiga-ui/i18n';
import { TUI_COUNTRIES_MASKS } from '@taiga-ui/kit';
import { AWSLambda } from 'src/app/AWSLambda.service';
import { ConfigService } from '../../config.service';
import { BalanceService } from '../../services/balance.service';
import { TPVService } from '../../services/tpv.service';
import { OptionItem, OptionItemAbstract } from '../../utils/Item';
import { CountriesModalComponent } from '../rates/countries-modal.component';
import { RatesModalComponent } from '../rates/rates-modal.component';
import { Rate, RatesModal } from '../rates/rates.model';
import { getCountryInfo } from './recharges.countries';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CompleteProfileModalComponent } from '../../components/modals/complete-profile-modal.component';
import { SpinnerService } from '../../components/spinner/spinner.service';
import { Auth } from 'aws-amplify';


@Component({
  selector: 'app-recharges',
  templateUrl: './recharges.component.html',
  styles: [
    `
      .loading-mask {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(
          0,
          0,
          0,
          0.5
        ); /* semi-transparent black background */
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999; /* Ensure it's on top of other content */
      }
    `,
  ],
})

export class RechargesComponent extends OptionItemAbstract implements OnInit {

  user!: AmplifyUser;

  authStatus$ = inject(AuthService).authStatus$;
  public override header: string = `recharge`;
  balance!: number;
  showSpinner = false;
  sendToProfile = true;

  here: string = `here`;
  header_form: string = `no_option_selected`;
  amount: string = '0';
  currency: string = `EUR`;
  balanceRefreshTick: number = Date.now();
  spinnerService = inject(SpinnerService)
  balanceSvc = inject(BalanceService);
  tpvService = inject(TPVService);
  dialog = inject(MatDialog);
  lambda = inject(AWSLambda);
  constructor(
    public configService: ConfigService,
    private authenticatorSvc: AuthenticatorService,
    private auth: AuthService,
    private router: Router,
    @Inject(TUI_IS_MOBILE) readonly isMobile: boolean,
    @Inject(TUI_COUNTRIES_MASKS)
    private readonly countriesMasks: Record<TuiCountryIsoCode, string>
  ) {
    super();
  }

  async checkUserExists(userId: string) {
    try {
      //console.log('Checking if user exists:', userId);
      // Llama a tu función Lambda para verificar si el usuario existe
      const response = await this.lambda.getLambdaFunction('lambda', '/user/whitelist', {
        queryStringParameters: {
          user: userId,
        },
      });

      //console.log('Lambda response:', response);

      // Parsea la respuesta de la función Lambda


      // Retorna true si se encontró el usuario, de lo contrario false
      return response.allowed;
    } catch (error) {
      //console.error('Error calling Lambda function:', error);
      return false; // Tratar el error como que el usuario no existe
    }
  }



  //Do Nothing
  go(route: string, arg_index: number): void {
    throw new Error('Method not implemented.');
  }

  async ngOnInit() {
    sessionStorage.removeItem('back_url');
    this.user = await Auth.currentAuthenticatedUser();
    const attributes = await Auth.userAttributes(this.user);
    this.spinnerService.showSpinner();

    try {
      const userSub = attributes.find(attribute => attribute.Name === 'sub')?.Value;
      const address = attributes.find(attribute => attribute.Name === 'custom:address')?.Value;
      const country = attributes.find(attribute => attribute.Name === 'custom:country')?.Value;
      const city = attributes.find(attribute => attribute.Name === 'custom:ciudad')?.Value;
      const province = attributes.find(attribute => attribute.Name === 'custom:provincia')?.Value;
      const zipcode = attributes.find(attribute => attribute.Name === 'custom:postcode')?.Value;
      const passport = attributes.find(attribute => attribute.Name === 'custom:passport')?.Value;

      this.sendToProfile = !(province !== "" && province !== undefined && address !== "" && address !== undefined && city !== "" && city !== undefined && zipcode !== "" && zipcode !== undefined && passport !== "" && passport !== undefined && country !== "" && country !== undefined && userSub !== "");

      if (userSub) {
        const userExists = await this.checkUserExists(userSub);
        console.log('User exists:', userExists);
        if (userExists) {
          // continuar
          this.calculateBalance();
          this.fetchItems();
        } else {
          console.error('User not found 1');
          this.router.navigate(['home']);
        }
      } else {
        console.error('User sub not found');
      }
    } catch (error) {
      console.error('Error during initialization:', error);
    }
    this.spinnerService.hideSpinner();
  }

  fetchItems() {
    const paypal = this.castToItem([
      {
        id: '5_wallet_to_make_calls',
        value: '5',
      },
      {
        id: '10_wallet_to_make_calls',
        value: '10',
      },
      {
        id: '20_wallet_to_make_calls',
        value: '20',
      },
      {
        id: '30_wallet_to_make_calls',
        value: '30',
      },
      {
        id: '50_wallet_to_make_calls',
        value: '50',
      },
      {
        id: '100_wallet_to_make_calls',
        value: '100',
      },
    ]);
    this.items = [...paypal];
  }

  castToItem(items: any[]) {
    return items.map((i) => {
      return {
        id: i.id,
        icon: 'shopping_cart',
        title: `Add ${i.value} EUR to make calls`,
        caption: 'Via TPV BBVA',
        caption_format: 'shopping_cart',
        value: i.value,
        args: [i.value],
      } as OptionItem;
    });
  }

  async calculateBalance() {
    this.balance = await this.balanceSvc.calculateBalance();
  }

  async topUp(amount: string) {
    this.showSpinner = true;
    await this.tpvService.createFormAndSendForm(amount, 'Saldo', 'tpv', '');
  }

  openCountriesModal() {
    this.dialog
      .open(CountriesModalComponent, {
        width: this.isMobile ? '85%' : '30%',
        panelClass: 'zendy-modal',
      })
      .afterClosed()
      .subscribe(async (countryCode: TuiCountryIsoCode) => {
        const rates: Rate = await this.getPricing(countryCode);
        if (!rates) return;
        this.openRatesModal(countryCode, rates);
      });
  }

  openProfileModal() {
    sessionStorage.setItem('back_url', '/recharges');
    this.dialog
      .open(CompleteProfileModalComponent, {
        width: this.isMobile ? '85%' : '20%',
        minHeight: '250px',
        panelClass: 'zendy-modal',
      })
      .afterClosed()
      .subscribe();
  }

  async getPricing(to: TuiCountryIsoCode) {
    if (this.countriesMasks[to]) {
      const countryCode = this.countriesMasks[to].replace(/[()\s#-]/g, '');


      const isoCode = getCountryInfo(countryCode, to, "ISO-3");

      return await this.lambda.getLambdaFunction('lambda', '/pricing/calls', {
        queryStringParameters: {
          from: '+34',
          to: countryCode,
          via: 'signalwire',
          filter: isoCode // Regexpress - aqui deberia ir el ISO CODE o el nombre del pais en ingles.
        },
      });
    }
  }

  openRatesModal(country: TuiCountryIsoCode, rates: Rate) {
    const data: RatesModal = {
      country,
      pricing: rates.pricing,
      prefix: rates.to,
    };
    this.dialog.open(RatesModalComponent, {
      width: this.isMobile ? '95%' : '30%',
      panelClass: 'zendy-modal',
      data,
    });
  }
}
