<mat-list *ngIf="!isGroupedBy">
  <mat-list-item *ngFor="let item of items">
    <div matListItemTitle class="!text-base" (click)="onClick.emit(item)">
      {{ item.title }}
      {{ item.caption }}
    </div>
    <button
      class="!text-light-red !m-0"
      *ngIf="itemActions.length > 0 || hasDetail || hasEdit || hasDelete"
      mat-icon-button
      [matMenuTriggerFor]="menu"
      (click)="setItemSelected(item.id)"
      matListItemMeta
    >
      <mat-icon>more_vert</mat-icon>
    </button>
  </mat-list-item>
</mat-list>
<ng-container *ngIf="isGroupedBy">
  <mat-list *ngFor="let item of groupedItems | keyvalue">
    <h3>{{ item.key }}</h3>
    <mat-list-item
      class="!pl-0 flex cursor-pointer"
      *ngFor="let contact of item.value; let last = last"
      [ngClass]="
        !last ? 'border-b border-solid border-gray-opacity' : 'text-gray'
      "
    >
      <p
        mat-line
        class="!text-base cursor-pointer"
        (click)="onClick.emit(contact)"
      >
        {{ contact.title }}
      </p>
      <button
        class="!text-light-red !m-0"
        *ngIf="itemActions.length > 0 || hasDetail || hasEdit || hasDelete"
        mat-icon-button
        [matMenuTriggerFor]="menu"
        (click)="setItemSelected(contact.id)"
        matListItemMeta
      >
        <mat-icon class="">more_vert</mat-icon>
      </button>
    </mat-list-item>
  </mat-list>
</ng-container>

<mat-menu #menu="matMenu">
  <ng-container *ngFor="let action of itemActions">
    <button *ngIf="showAddContactAction(action)" mat-menu-item (click)="go(action.route, action.arg_index)">
      <mat-icon>{{ action.icon }}</mat-icon>
      <span>{{ action.name | translate }}</span>
    </button>
  </ng-container>
  <button mat-menu-item *ngIf="hasDetail" (click)="onDetail()">
    <mat-icon>plagiarism</mat-icon>
    <span>{{ 'COMPONENTS.LIST.DETAILS' | translate }}</span>
  </button>
  <button mat-menu-item *ngIf="hasEdit" (click)="onEdit()">
    <mat-icon>edit</mat-icon>
    <span>{{ 'COMPONENTS.LIST.EDIT' | translate }}</span>
  </button>
  <button mat-menu-item *ngIf="hasDelete" (click)="onDelete()">
    <mat-icon>delete</mat-icon>
    <span>{{ 'COMPONENTS.LIST.DELETE' | translate }}</span>
  </button>
  <button mat-menu-item (click)="onView()">
    <mat-icon>visibility</mat-icon>
    <span>{{ 'COMPONENTS.LIST.VIEW' | translate }}</span>
  </button>
</mat-menu>

<div
  class="warning"
  *ngIf="
    (!isGroupedBy && items && items.length === 0) ||
    (isGroupedBy && (groupedItems | json) == ({} | json))
  "
>
  <div class="flex justify-center">
    <img class="mb-2 w-40 md:w-28" src="./assets/icons/no-records.svg" />
  </div>
  <h2>{{ 'COMPONENTS.LIST.NO_RESULTS_TITLE' | translate }}</h2>
  <p class="text-base mb-8">
    {{ 'COMPONENTS.LIST.NO_RESULTS_DESC' | translate }}
  </p>
</div>
