<app-list
  [isGroupedBy]="true"
  (delete)="onDelete($event)"
  (edit)="onEdit($event)"
  (view)="onView($event)"
  [hasEdit]="editActiveinList"
  [hasDelete]="deleteActiveinList"
  [actions]="actions"
  [router]="router"
  [setItemSelected]="setItemSelected"
  [getItemSelected]="getItemSelected"
  [groupedItems]="groupedItems"
  [itemActions]="itemActions"
  [go]="go"
  (onClick)="onClick($event)"
>
</app-list>
