import { Component, inject, Input, OnInit } from '@angular/core';
import { BalanceService } from '../../services/balance.service';
import { ConfigService } from 'src/app/config.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-zendy-header-desktop',
  template: `
    <div
      class="w-full bg-gray-opacity rounded-2xl px-4 py-4 mt-8 mb-8 flex flex-col items-center"
    >
      <div *ngIf="showCard" class="flex flex-row justify-between w-full">
        <div class="flex flex-row w-full gap-8 items-center">
          <img
            class="rotate-minus12"
            width="112px"
            src="./assets/icons/card.svg"
            alt="card"
          />
          <p
            class="flex flex-row mb-2 mt-4 mr-2 tracking-tight text-gray items-center gap-2"
          >
            {{ 'COMPONENTS.HEADER.BALANCE' | translate }}
          </p>
          <div class="flex flex-col items-end">
            <span class="text-light-red text-2xl items-end">
              {{ balance | currency : currency : 'symbol' }}
            </span>
            <a class="text-sm text-light-red" routerLink="/recharges">{{
              'COMPONENTS.HEADER.ADD' | translate
            }}</a>
          </div>
        </div>
        <div *ngIf="showButtons" class="flex flex-row w-full gap-8 justify-end">
          <a routerLink="/calls">
            <div class="flex flex-col items-center gap-2">
              <img src="./assets/icons/new-call.svg" />
              <div class="text-gray text-center">
                {{ 'COMPONENTS.HEADER.NEW_CALL' | translate }}
              </div>
            </div>
          </a>
          <div
            class="flex flex-col items-center gap-2 cursor-pointer"
            (click)="goToNewTransfer()"
          >
            <img src="./assets/icons/new-transfer.svg" />
            <div class="text-gray text-center">
              {{ 'COMPONENTS.HEADER.NEW_TRANSFER' | translate }}
            </div>
          </div>
           <a routerLink="/contacts/new-contact">
            <div class="flex flex-col items-center gap-2">
              <img src="./assets/icons/new-contact.svg" />
              <div class="text-gray text-center">
                {{ 'COMPONENTS.HEADER.NEW_CONTACT' | translate }}
              </div>
            </div>
           </a>
        </div>
      </div>
    </div>
  `,
})
export class ZendyHeaderDesktopComponent implements OnInit {
  private balanceSvc = inject(BalanceService);
  transfersUrl = `${inject(ConfigService).config.transfersUrl}?tracker=${
    inject(AuthService).getUser().id
  }`;

  @Input() showCard = true;
  @Input() showButtons = true;

  balance!: number;
  currency: string = `EUR`;
  ngOnInit() {
    this.calculateBalance().then(balance => {
      this.balance = balance;
    });
  }
  async calculateBalance(): Promise<number> {
    await this.delay(3000)
    return await this.balanceSvc.calculateBalance();
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  goToNewTransfer() {
    window.open(this.transfersUrl, '_blank');
  }
}
