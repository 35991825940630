import { Location } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { APIService, Contact } from 'src/app/API.service';
import { AuthService } from 'src/app/services/auth.service';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { TuiCountryIsoCode } from '@taiga-ui/i18n';
import { CHAR_PLUS, TUI_IS_MOBILE } from '@taiga-ui/cdk';
import { TUI_COUNTRIES_MASKS } from '@taiga-ui/kit/tokens';

@Component({
  selector: 'app-new-contact',
  templateUrl: './new-contact.component.html',
})
export class NewContactComponent implements OnInit {
  contactId = this.route.snapshot.params['id'];
  telephone = this.route.snapshot.params['telephone'];
  labelTelephone = this.route.snapshot.params['label'];
  createForm: FormGroup = this.fb.group({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.email]),
    telephone: new FormControl('', [
      Validators.required,
      Validators.pattern(
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
      ),
    ]),
  });

  readonly countries = Object.values(TuiCountryIsoCode);
  countryIsoCode = TuiCountryIsoCode.ES;


  constructor(
    private fb: FormBuilder,
    private api: APIService,
    private authService: AuthService,
    private location: Location,
    private route: ActivatedRoute,
    @Inject(TUI_COUNTRIES_MASKS) private readonly countriesMasks: Record<TuiCountryIsoCode, string>,
  ) { }

  ngOnInit(): void {

    if (this.telephone && this.telephone.size > 4) {
      this.countryIsoCode = this.getCountryIsoCode(this.telephone);
      this.createForm.patchValue({
        telephone: this.telephone,
      });
    }

    if (this.contactId) {
      this.api.GetContact(this.contactId).then((data) => {
        this.createForm.patchValue(data);
      });
    } else if (this.telephone) {
      this.createForm.patchValue({
        telephone: this.telephone,
      });
    }
  }

  getCountryIsoCode(value: string) {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const phoneNumber = phoneUtil.parse(CHAR_PLUS + value);
    const region = phoneUtil.getRegionCodeForNumber(phoneNumber);
    if (region !== undefined) {
      return TuiCountryIsoCode[region as TuiCountryIsoCode];
    }
    return TuiCountryIsoCode.ES;
  }

  async onCreate() {
    const contact: Contact = {
      ...this.createForm.getRawValue(),
      owner: this.authService.getUser().username ?? '',
    };
    await this.api.CreateContact(contact).then(() => {
      this.goBack();
      this.createForm.reset();
    });
  }

  async onUpdate() {
    const contact: Contact = {
      ...this.createForm.getRawValue(),
      id: this.contactId,
    };
    await this.api.UpdateContact(contact).then(() => {
      this.goBack();
      this.createForm.reset();
    });
  }

  goBack() {
    this.location.back();
  }
}
