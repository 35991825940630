import { TUI_IS_MOBILE } from '@taiga-ui/cdk';
import { MatDialog } from '@angular/material/dialog';
import { Component, Inject, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EsimService } from '../esim.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { AuthService } from 'src/app/services/auth.service';
import { CompleteProfileModalComponent } from '../../../components/modals/complete-profile-modal.component';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'app-esim-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
  styles: [
    `
      .loading-mask {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(
          0,
          0,
          0,
          0.5
        ); /* semi-transparent black background */
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999; /* Ensure it's on top of other content */
      }
    `,
  ],
})

export class DetailsComponent implements OnInit {
  acceptTermsCheckbox = false;
  acceptDevicesCheckbox = false;
  showSpinner = false;
  usageData: any = null;
  details: any;
  iccid: string = '';
  country: string = '';
  operator: string = '';
  installationGuide: any = null;
  selectedPlatform: 'android' | 'ios' | null = null;
  isLoading: boolean = false;
  isLoaded: boolean = false;
  esimDetails: any = null;
  price: number | null = null;

  planType: string = '';
  countryimg: string = '';
  operatorDetails: any;

  dialog = inject(MatDialog);
  sendToProfile = true;

  private user = inject(AuthenticatorService).user;
  auth = inject(AuthService);

  constructor(
    private esimService: EsimService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    @Inject(TUI_IS_MOBILE) readonly isMobile: boolean,
    private router: Router
  ) { }

  async getPackage(id: string) {
    const apiPackages = await this.esimService.getfilteredPackagesbyID(id);

    if (!apiPackages) {
      console.error('Response is undefined or empty');
      return;
    }

    const apiResponse = typeof apiPackages === 'string' ? JSON.parse(apiPackages) : apiPackages;
    const packagesMap: { [key: string]: { country: string, image: string, packages: any[] } } = {};
    const tmpPackage: any[] = [];
    // Procesar cada paquete
    apiResponse.forEach((pkg: any) => {
      if (pkg.pkg_country_code) {
        // Verificar si el país ya está en el mapa
        if (!packagesMap[pkg.pkg_country_code]) {
          // Crear una nueva entrada en el mapa para este país
          packagesMap[pkg.pkg_country_code] = {
            country: pkg.pkg_country,
            image: pkg.pkg_country_url || 'https://cdn-dev.zendy.tel/images/Promo-Zendy-3-24@3x.png', // Proporciona una ruta por defecto si es necesario
            packages: []
          };
        }

        // Aquí agregamos la lógica del `type` para cada paquete
        const type = pkg.pkg_type || 'Local'; // Usa "Local" como predeterminado si no se encuentra `pkg_type`

        tmpPackage.push({
          country: pkg.pkg_country,
          countryimg: packagesMap[pkg.pkg_country_code].image,
          voice: pkg.pkg_voice,
          amount: pkg.pkg_amount,
          short_info: pkg.pkg_short_info,
          data: pkg.pkg_data,
          manual_installation: pkg.pkg_manual_installation,
          net_price: pkg.pkg_price,
          type: type, // Asignar el `type` al paquete
          title: pkg.pkg_title,
          is_unlimited: pkg.pkg_is_unlimited,
          qr_installation: pkg.pkg_qr_installation,
          price: pkg.pkg_price,
          id: pkg.pkg_id,
          text: pkg.pkg_text,
          day: pkg.pkg_day,
          operador: [{
            operador: pkg.operador_title,
            type: pkg.operador_plan_type,
            prepaid: pkg.pkg_is_prepaid === 'true',
            esimtype: pkg.operador_apn_type,
            warning: pkg.operador_warning,
            apn_type: pkg.operador_apn_type,
            apn_value: pkg.operador_apn_value,
            is_roaming: pkg.operador_is_roaming,
            info: pkg.operador_info,
            image: pkg.operador_url || 'https://cdn-dev.zendy.tel/images/Promo-Zendy-3-24@3x.png',
            plan_type: pkg.operador_plan_type,
            activation_policy: pkg.operador_activation_policy,
            is_kyc_verify: pkg.operador_is_kyc_verify,
            rechargeability: pkg.operador_rechargeability,
            other_info: pkg.operador_other_info
          }]
        });
      }
    });

    sessionStorage.removeItem('back_url');
    sessionStorage.removeItem('package_id');

    return tmpPackage[0];
  }

  async ngOnInit(){

    await this.auth.refresh();
    this.user = await Auth.currentAuthenticatedUser();
    const attributes = await Auth.userAttributes(this.user);

    const userSub = attributes.find(attribute => attribute.Name === 'sub')?.Value;
    const address = attributes.find(attribute => attribute.Name === 'custom:address')?.Value;
    const country = attributes.find(attribute => attribute.Name === 'custom:country')?.Value;
    const city = attributes.find(attribute => attribute.Name === 'custom:ciudad')?.Value;
    const province = attributes.find(attribute => attribute.Name === 'custom:provincia')?.Value;
    const zipcode = attributes.find(attribute => attribute.Name === 'custom:postcode')?.Value;
    const passport = attributes.find(attribute => attribute.Name === 'custom:passport')?.Value;

    this.sendToProfile = !(address !== "" && address !== undefined && city !== "" && city !== undefined && zipcode !== "" && zipcode !== undefined && passport !== "" && passport !== undefined && country !== "" && country !== undefined && userSub !== "");

    let back_package_id = sessionStorage.getItem('package_id');
    if (back_package_id != null && back_package_id != '') {
      this.esimDetails = await this.getPackage(back_package_id);
    } else {
      this.esimDetails = history.state.data;
    }

    if (this.esimDetails) {
      this.iccid = this.esimDetails.iccid ? this.esimDetails.iccid : '';
      this.country = this.esimDetails.country;
      this.countryimg = this.esimDetails.operador ? this.esimDetails.countryimg : this.esimDetails.pkg_details.country_url;
      this.operator = this.esimDetails.operador ? this.esimDetails.operador[0].operador : this.esimDetails.operator;
      this.planType = this.esimDetails.operador ? this.esimDetails.operador[0].plan_type : this.esimDetails.pkg_details.operator_info.plan_type;
      this.usageData = this.esimDetails.usage_data;
      this.price = this.esimDetails.operador ? this.esimDetails.price : null;

      this.operatorDetails = this.esimDetails.operador ? this.esimDetails.operador[0] : this.esimDetails.pkg_details;
      if (this.iccid !== '') {
        this.fetchInstallationGuide(this.iccid);
       
        this.details = <EsimDetails>{
          country: this.esimDetails.country,
          short_info: null,
          data: this.esimDetails.pkg_details.data,
          type: this.esimDetails.pkg_details.type,
          title: this.esimDetails.pkg_details.title,
          is_unlimited: this.esimDetails.pkg_details.is_unlimited,
          day: this.esimDetails.pkg_details.day,
          plan_type: this.esimDetails.pkg_details.operator_info.plan_type,
          prepaid: this.esimDetails.pkg_details.operator_info.is_prepaid,
          esimtype: this.esimDetails.pkg_details.operator_info.esim_type,
          warning: this.esimDetails.pkg_details.operator_info.warning,
          apn_type: this.esimDetails.pkg_details.operator_info.apn_type,
          info: this.esimDetails.pkg_details.info,
          activation_policy: this.esimDetails.pkg_details.operator_info.activation_policy,
          rechargeability: this.esimDetails.pkg_details.operator_info.rechargeability,
          other_info: this.esimDetails.pkg_details.operator_info.other_info
        };

      } else {
        this.details = <EsimDetails>{
          country: this.esimDetails.country,
          short_info: this.esimDetails.short_info,
          data: this.esimDetails.data,
          type: this.esimDetails.type,
          title: this.esimDetails.title,
          is_unlimited: this.esimDetails.is_unlimited,
          day: this.esimDetails.day,
          plan_type: this.esimDetails.operador[0].plan_type,
          prepaid: this.esimDetails.operador[0].prepaid,
          esimtype: this.esimDetails.operador[0].esimtype,
          warning: this.esimDetails.operador[0].warning,
          apn_type: this.esimDetails.operador[0].apn_type,
          info: this.esimDetails.operador[0].info,
          activation_policy: this.esimDetails.operador[0].activation_policy,
          rechargeability: this.esimDetails.operador[0].rechargeability,
          other_info: this.esimDetails.operador[0].other_info
        };
      }
    }
  }

  openProfileModal() {
    sessionStorage.setItem('back_url', '/esim-details');
    sessionStorage.setItem('package_id', this.esimDetails.id);

    this.dialog
      .open(CompleteProfileModalComponent, {
        width: this.isMobile ? '85%' : '20%',
        minHeight: '250px',
        panelClass: 'zendy-modal',
      })
      .afterClosed()
      .subscribe();
  }

  calculatePercentage(total: number | undefined, remaining: number | undefined): number {
    if (!total || !remaining) {
      return 0;
    }
    return ((total - remaining) / total) * 100;
  }

  // Convertir MB a GB
  convertDataUnits(value: number | null | undefined): string {
    if (!value) {
      return '0 MB';
    }

    if (value >= 1024) {
      const gbValue = (value / 1024).toFixed(2);
      return `${gbValue} GB`;
    } else {
      return `${value} MB`;
    }
  }



  fetchInstallationGuide(iccid: string): void {
    this.isLoading = true;
    this.esimService.getInstallationGuide(iccid).then(response => {
      if (response && response.data && response.data.instructions) {
        this.installationGuide = {
          ios: response.data.instructions.ios[0],
          android: response.data.instructions.android[0]
        };
      }
      this.isLoading = false;
    }).catch(error => {
      console.error('Error fetching installation guide:', error);
      this.isLoading = false;
    });
  }

  selectPlatform(platform: 'android' | 'ios'): void {
    this.selectedPlatform = platform;
  }

  goBack(): void {
    this.router.navigate(['/esim']);
  }

  onRecharge(iccid: string): void {
    this.router.navigate(['/topup', iccid]);
  }

  async onBuyNow(): Promise<void> {
    this.showSpinner = true;
    // const packageId = this.data.package.id;

    // const userSub = this.authenticatorSvc.user?.attributes?.["sub"];

    // if (userSub) {
    //   this.IsOnline = true;
    // }

    // Simula la verificación de si el usuario está logueado
    const usuario = await this.esimService.getusuario();
    //console.log('isLoggedIn', usuario);
    if (usuario == '') {
      // Abre el modal de login
      this.esimService.openLoginModal();
      return;
    }
    this.isLoading = true;

    const { id: packageId, title: packageTitle, price: price } = this.esimDetails;
    //console.log(this.data);
    const { country } = this.esimDetails;
    const operatorTitle = this.esimDetails.operador[0].operador;
    const tpvType = 'Esim';
    const amount = '0';

    if (packageId) {
      try {

        const orderData = {
          packageId,
          country,
          operatorTitle,
          price,
        };

        const result = await this.esimService.submitOrder(orderData, tpvType, amount);
        this.snackBar.open('Order submitted successfully', 'Close', { duration: 3000 });
      } catch (error) {
        console.error('Order submission failed', error);
        this.snackBar.open('Order submission failed', 'Close', { duration: 3000 });
      }
    } else {
      console.error('No package_id');
      this.snackBar.open('No package_id found', 'Close', { duration: 3000 });
    }
    this.showSpinner = false;
  }
}
export interface EsimDetails {
  country: string;
  short_info: string | null;
  data: string;
  type: string;
  title: string;
  is_unlimited: string;
  price: number | null;
  day: string;
  plan_type: string;
  prepaid: string;
  esimtype: string;
  warning: string;
  apn_type: string;
  info: string;
  activation_policy: string;
  rechargeability: string;
  other_info: string;
}
