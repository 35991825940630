<div class="container mx-auto p-4">
  <h2 class="text-xl font-bold text-center">
    <mat-icon (click)="goBack()" class="float-left ms-4 cursor-pointer">arrow_back_ios</mat-icon>{{ 'ESIM.DETAILS.TITLE' | translate }}
  </h2>
  <div>
    <div class="container mx-auto p-4">
      <!-- Contenedor del eSIM -->
      <div class="esim-card-container">
        <mat-card class="esim-card">
          <div class="absolute left-0 ml-4 p-2 flex" style="height:fit-content;">
            <div *ngFor="let item of planType.split('-')">
              <img width="16px" [src]="'./assets/icons/' + item + '_ico.svg'" title="{{ item }}" class="mx-1" />
            </div>
          </div>

          <div class="rounded-full bg-white absolute right-0 mr-4 p-2" style="height:fit-content;">
            <span class=" flag large block content-center"><img src="{{ countryimg }}" /></span>
          </div>

          <mat-card-header class="mb-2">
            <div class="esim-details-image-container relative float-end">
              <img src="https://cdn-dev.zendy.tel/images/Esim/EsimCard@2x.png" alt="eSIM Image" class="esim-details-image" />
              <span class="esim-details-country-label">{{ country }}</span> <!-- Aquí agregamos el país -->
            </div>
          </mat-card-header>

          <mat-card-content>
            <p><strong>{{ country | titlecase }}</strong></p>
            <p *ngIf="( iccid !== '')"><strong>ICCID: </strong>{{ iccid }}</p>
            <h2 class="m-1 text-5xl" *ngIf="price"><strong>{{ price | currency:'EUR':'symbol':'1.2-2':'es-ES' }}</strong></h2>
            <p><strong>{{ 'ESIM.OPERATOR' | translate }}: </strong> {{ operator }}</p>
          </mat-card-content>

          <mat-card-actions class="mat-card-actions" *ngIf="( iccid !== '')">
            <button mat-button class="recharge-button" (click)="onRecharge(iccid)">
              {{ 'ESIM.DETAILS.RECHARGE_NOW' | translate }}
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>

    <!-- Data Usage Section -->
    <div class="usage-section grid-cols-3" *ngIf="usageData">
      <!-- Data -->
      <mat-card *ngIf="(usageData?.total != 0 && iccid !== '')" class="usage-card w-full">
        <mat-card-header>
          <div class="header-content flex justify-between">
            <mat-card-title class="usage-title">{{ 'ESIM.DETAILS.DATA' | translate }}</mat-card-title>
            <div class="usage-info">
              {{ 'ESIM.DETAILS.USED' | translate }}:
              {{ convertDataUnits(usageData?.total - usageData?.remaining || 0) }}
              {{ 'ESIM.OF' | translate }} {{ convertDataUnits(usageData?.total || 0) }}
            </div>
          </div>
        </mat-card-header>
        <mat-card-content>
          <mat-progress-bar mode="determinate"
                            [value]="calculatePercentage(usageData?.total || 0, usageData?.remaining || 0)"
                            class="custom-progress-bar">
          </mat-progress-bar>
          <p class="remaining-info">
            {{ 'ESIM.DETAILS.YOU_HAVE' | translate }} {{ convertDataUnits(usageData?.remaining || 0) }}
          </p>
        </mat-card-content>
      </mat-card>

      <!-- Voice -->
      <mat-card *ngIf="(usageData?.total_voice != 0 && iccid !== '')" class="usage-card w-full">
        <mat-card-header>
          <div class="header-content flex justify-between">
            <mat-card-title class="usage-title">{{ 'ESIM.DETAILS.VOICE' | translate }}</mat-card-title>
            <div class="usage-info">
              {{ 'ESIM.DETAILS.USED' | translate }}:
              {{ usageData.total_voice - usageData.remaining_voice }} min
              {{ 'ESIM.OF' | translate }} {{ usageData.total_voice }} min
            </div>
          </div>
        </mat-card-header>
        <mat-card-content>
          <mat-progress-bar mode="determinate"
                            [value]="calculatePercentage(usageData.total_voice, usageData.remaining_voice)">
          </mat-progress-bar>
          <p class="remaining-info">
            {{ 'ESIM.DETAILS.YOU_HAVE' | translate }} {{ usageData.remaining_voice }}
            {{ 'ESIM.DETAILS.MINUTES' | translate }}
          </p>
        </mat-card-content>
      </mat-card>

      <!-- SMS -->
      <mat-card *ngIf="(usageData?.total_text != 0 && iccid !== '')" class="usage-card w-full">
        <mat-card-header>
          <div class="header-content flex justify-between">
            <mat-card-title class="usage-title">SMS</mat-card-title>
            <div class="usage-info">
              {{ 'ESIM.DETAILS.USED' | translate }}:
              {{ usageData.total_text - usageData.remaining_text }} SMS
              {{ 'ESIM.OF' | translate }} {{ usageData.total_text }}
            </div>
          </div>
        </mat-card-header>
        <mat-card-content>
          <mat-progress-bar mode="determinate"
                            [value]="calculatePercentage(usageData.total_text, usageData.remaining_text)">
          </mat-progress-bar>
          <p class="remaining-info">
            {{ 'ESIM.DETAILS.YOU_HAVE' | translate }} {{ usageData.remaining_text }}
            {{ 'ESIM.DETAILS.MESSAGES' | translate }}
          </p>
        </mat-card-content>
      </mat-card>

    </div>
  </div>

  <div class="container mx-auto p-4 grid gap-4 {{ iccid == '' ? '' : 'grid-cols-5' }}">

    <!-- QR Code Section -->
    <div class="qr-install-container col-span-2 h-fit" *ngIf="( iccid !== '')">
      <mat-card class="qr-card">
        <h2 class="text-xl font-bold">{{ 'ESIM.DETAILS.QR_AND_INSTALATION' | translate }}</h2>
        <mat-card-header>
          <!-- <mat-card-title class="text-red-600 font-bold">QR e instalación</mat-card-title> -->
        </mat-card-header>
        <mat-card-content class="mb-4">
          <!-- <p class="font-bold text-center text-lg">
      {{ 'ESIM.DETAILS.QR_TEXT' | translate }}
  </p> -->
          <div class="qr-image-container">
            <img [src]="esimDetails.details.qrcode_url" alt="QR Code" class="qr-code-image">
          </div>

          <div class="w-full my-6">
            <p class="font-bold mb-2">
              {{ 'ESIM.DETAILS.ACTIVATION_URL' | translate }}
            </p>
            <p class="w-full border p-2 rounded-md bg-white">{{installationGuide['ios'].installation_manual.smdp_address}}</p>
          </div>

          <div class="w-full my-6">
            <p class="font-bold mb-2">
              {{ 'ESIM.DETAILS.ACTIVATION_CODE' | translate }}
            </p>
            <p class="w-full border p-2 rounded-md bg-white">{{installationGuide['ios'].installation_manual.activation_code}}</p>
          </div>

          <p class="font-bold text-center text-lg">
            {{ 'ESIM.DETAILS.INSTALATION_INSTRUCTIONS' | translate }}
          </p>
          <div class="installation-buttons">
            <button mat-button class="install-button" (click)="selectPlatform('android')">
              <mat-icon>android</mat-icon> Android
            </button>
            <button mat-button class="install-button" (click)="selectPlatform('ios')">
              <mat-icon>apple</mat-icon> iOS
            </button>
          </div>
        </mat-card-content>

        <!-- Expansion Panel for Instructions -->
        <mat-expansion-panel *ngIf="selectedPlatform && installationGuide[selectedPlatform]"
                             class="installation-panel !m-2">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'ESIM.DETAILS.INSTALATION_INSTRUCTIONS_FOR' | translate }} {{ selectedPlatform | uppercase }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="installation-content">
            <div class="mt-2">
              <h4>{{ 'ESIM.DETAILS.QR_AND_INSTALATION' | translate }}</h4>
              <ul>
                <li *ngFor="let step of installationGuide[selectedPlatform].installation_via_qr_code.steps | keyvalue">
                  <label class="font-bold">
                    {{ 'ESIM.DETAILS.STEP' | translate }} {{ step.key }}:
                  </label> {{ step.value }}
                </li>
              </ul>
            </div>

            <div class="mt-2">
              <h4>{{ 'ESIM.DETAILS.MANUAL_INSTALATION' | translate }}</h4>
              <ul>
                <li *ngFor="let step of installationGuide[selectedPlatform].installation_manual.steps | keyvalue">
                  <label class="font-bold">
                    {{ 'ESIM.DETAILS.STEP' | translate }} {{ step.key }}:
                  </label> {{ step.value }}
                </li>
              </ul>
            </div>

            <div class="mt-2">
              <h4>{{ 'ESIM.DETAILS.NETWORK_SETUP' | translate }}</h4>
              <ul>
                <li *ngFor="let step of installationGuide[selectedPlatform].network_setup.steps | keyvalue">
                  <label class="font-bold">
                    {{ 'ESIM.DETAILS.STEP' | translate }} {{ step.key }}:
                  </label> {{ step.value }}
                </li>
              </ul>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-card>
    </div>

    <div class="card-details-container col-span-3">
      <h2 class="text-xl font-bold">{{ 'ESIM.DETAILS.ADDITIONAL_INFORMATION' | translate }}</h2>
      <div *ngFor="let item of details | keyvalue">
        <div class=" flex justify-start mb-2" *ngIf="item.value != undefined">
          <div class="flex-none w-4 me-2"><img width="16" height="16" src="./assets/icons/sim_card.svg" /></div>
          <div class="w-fit flex-none font-bold me-2 ">{{ 'ESIM.DETAILS.'+item.key | uppercase | translate }}:</div>
          <div class=" ">{{ item.value+'' | titlecase | translate }}</div>
        </div>
      </div>

    </div>
  </div>

  <div class="">
    <div class="container mx-auto p-4 text-center" *ngIf="iccid == ''; else withIccd">
      <div class="check-full-width mb-2">
        <mat-label for="acceptTermsCheckbox">
          <input type="checkbox" id="acceptTermsCheckbox" name="acceptTermsCheckbox" [(ngModel)]="acceptTermsCheckbox">
          {{ 'ONBOARDING.REGISTER.TERMS_START' | translate }} <a target='_blank' href='https://zendy.tel/privacy'>{{ 'ONBOARDING.REGISTER.TERMS_PRIVACY' | translate }}</a> {{ 'ONBOARDING.REGISTER.TERMS_AND' | translate }} <a target='_blank' href='https://zendy.tel/terms'>{{ 'ONBOARDING.REGISTER.TERMS_TERMS' | translate }}</a>
        </mat-label>
      </div>
      <div class="check-full-width mb-2">
        <mat-label for="acceptTermsCheckbox">
          <input type="checkbox" [(ngModel)]="acceptDevicesCheckbox" id="acceptDevicesCheckbox" name="acceptDevicesCheckbox">
          {{ 'ESIM.CONFIRM_DEVICES' | translate }} <a target='_blank' href='https://zendy.tel/dispositivos-compatibles'>{{ 'ESIM.DEVICES' | translate }}</a>
        </mat-label>
      </div>
      <div class="text-center mb-2">
        <button mat-raised-button [disabled]="!acceptTermsCheckbox || !acceptDevicesCheckbox" color="primary" (click)="sendToProfile ? openProfileModal() : onBuyNow()">{{ 'ESIM.BUY_ESIM' | translate }}</button>
      </div>
    </div>

    <ng-template #withIccd>
      <div class="text-center mb-2">
        <button mat-raised-button color="primary" (click)="goBack()">{{ 'ESIM.DETAILS.BTN_BACK' | translate }}</button>
      </div>
    </ng-template>

    <div class="loading-mask" *ngIf="showSpinner">
      <mat-spinner diameter="50" strokeWidth="3"></mat-spinner>
    </div>
  </div>
