import { AmplifyUser } from '@aws-amplify/ui';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EsimService } from '../esim.service'; // Importa tu servicio
import { MatSnackBar } from '@angular/material/snack-bar'; // Importa el MatSnackBar
import { MatDialog } from '@angular/material/dialog'; // Importa MatDialog para abrir modales
import { PurchaseDetailsModalComponent } from '../purchase-details-modal/purchase-details-modal.component'; // Importa el modal de detalles de compra
import { Auth } from 'aws-amplify';
import { CompleteProfileModalComponent } from '../../../components/modals/complete-profile-modal.component';
import { TUI_IS_MOBILE } from '@taiga-ui/cdk';

@Component({
  selector: 'app-topup',
  templateUrl: './topup.component.html',
  styleUrls: ['./topup.component.scss']
})
export class TopupComponent implements OnInit {
  iccid: string | null = '';
  esimDetails: any = {};  // Detalles del eSIM
  topups: any[] = [];     // Lista de topups
  showSpinner = false;
  sendToProfile = true;
  user!: AmplifyUser;

  constructor(
    private route: ActivatedRoute,
    private esimService: EsimService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
    @Inject(TUI_IS_MOBILE) readonly isMobile: boolean,
  ) {}

  calculatePercentage(total: number | undefined, remaining: number | undefined): number {
    if (!total || !remaining) {
      return 0;
    }
    return ((total - remaining) / total) * 100;
  }

  goBack(): void {
    this.router.navigate(['/esim']);
  }

  openProfileModal() {
    sessionStorage.setItem('back_url', '/topup/' + this.iccid);
    this.dialog
      .open(CompleteProfileModalComponent, {
        width: this.isMobile ? '85%' : '20%',
        minHeight: '250px',
        panelClass: 'zendy-modal',
      })
      .afterClosed()
      .subscribe();
  }

  async ngOnInit() {
    sessionStorage.removeItem('back_url');
    this.user = await Auth.currentAuthenticatedUser();
    const attributes = await Auth.userAttributes(this.user);

    const userSub = attributes.find(attribute => attribute.Name === 'sub')?.Value;
    const address = attributes.find(attribute => attribute.Name === 'custom:address')?.Value;
    const country = attributes.find(attribute => attribute.Name === 'custom:country')?.Value;
    const city = attributes.find(attribute => attribute.Name === 'custom:ciudad')?.Value;
    const province = attributes.find(attribute => attribute.Name === 'custom:provincia')?.Value;
    const zipcode = attributes.find(attribute => attribute.Name === 'custom:postcode')?.Value;
    const passport = attributes.find(attribute => attribute.Name === 'custom:passport')?.Value;

    this.sendToProfile = !(province !== "" && province !== undefined && address !== "" && address !== undefined && city !== "" && city !== undefined && zipcode !== "" && zipcode !== undefined && passport !== "" && passport !== undefined && country !== "" && country !== undefined && userSub !== "");

    // obtener el iccid de la URL
    this.esimDetails = JSON.parse(sessionStorage.getItem('esimDetails')+'')[0];
    this.iccid = this.esimDetails ? this.esimDetails?.iccid : this.route.snapshot.paramMap.get('iccid');

    if (this.iccid) {
      /*this.getEsimDetails(this.iccid);*/
      this.getAvailableTopups(this.iccid);
    }
  }

  getEsimDetails(iccid: string): void {
    this.esimService.getEsimDetails(iccid).then((details) => {
      this.esimDetails = details;
    });
  }

  getAvailableTopups(iccid: string): void {
    this.esimService.topupList(iccid).then((topups) => {
      this.topups = topups;
      //console.log(this.topups);
    }).catch(error => {
      console.error('Error al obtener la lista de topups:', error);
    });
  }

  // Método para manejar la recarga (topup)
  async submitTopUp(topupId: string): Promise<void> {
    this.showSpinner = true;
    const amount = '0';
    const tpvType = 'Esim';

    const topup = this.topups.filter(t => t.id == topupId);

    try {
      const order = await this.esimService.submitTopUpOrder(topup[0].price, tpvType, topupId, this.iccid!);
      this.snackBar.open('Recarga exitosa!', 'Cerrar', { duration: 3000 });

      // Abre el modal de detalles de compra
      this.openPurchaseDetailsModal(order);
    } catch (error) {
      console.error('Error al realizar la recarga', error);
      this.snackBar.open('Error al realizar la recarga. Por favor intenta nuevamente.', 'Cerrar', { duration: 3000 });
    } finally {
      this.showSpinner = false;
    }
  }

  // Método para abrir el modal de detalles de la compra
  openPurchaseDetailsModal(order: any): void {
    this.dialog.open(PurchaseDetailsModalComponent, {
      data: { order }
    });
  }
}
