import { Component, inject } from '@angular/core';
import { EsimService } from './esim.service';
import { AWSLambda } from 'src/app/AWSLambda.service';
import { MatDialog } from '@angular/material/dialog';
import { NgSelectModule } from '@ng-select/ng-select';
import { Imagebuilder } from 'aws-sdk';
import { BuyNowModalComponent } from './buy-now-modal/buy-now-modal.component';
import { TopupsModalComponent } from './topups-modal/topups-modal.component';
import { EsimDetailsModalComponent } from './esim-details-modal/esim-details-modal.component';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Auth } from 'aws-amplify';


@Component({
  selector: 'app-esim',
  templateUrl: './esim.component.html',
  styleUrls: ['./esim.component.scss']
})
export class EsimComponent {
  private authService = inject(AuthService);
  loginForm: FormGroup;
  usuario: string = '';
  public IsOnline: boolean = false;
  public showEsims: boolean = false;
  public showSearchEsim: boolean = true;
  public showSpinner = false;

  public isDisabled = true;

  countries: any[] = [];
  selectedCountry: string = '';
  packages: any[] = [];
  filteredPackages: any[] = [];
  packagesByCountry: { country: string, code: string, image: string, packages: any[] }[] = [];
  selectedType: string = '';
  types: string[] = ['Local', 'Regional','Global'];
  filteredCountries: any[] = [];
  tmpCountries: any[] = [];
  userEsims: any[] = [];

  datapackage: {
    voice: any,
    amount: any,
    short_info: any,
    data: any,
    manual_installation: any,
    net_price: any,
    type: any,
    title: any,
    is_unlimited: any,
    qr_installation: any,
    price: any,
    id: any,
    text: any,
    day: any
  }[] = [];

  operadorpkg: {
    name: any,
    type: any,
    prepaid: any,
    esimtype: any,
    warning: any,
    apn_type: any,
    apn_value: any,
    is_roaming: any,
    info: any,
    image: string,
    plan_type: any,
    activation_policy: any,
    is_kyc_verify: any,
    rechargeability: any,
    other_info: any,
  }[] = [];

  //oninit
  constructor(
    private esimService: EsimService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private router: Router,
    private translateService: TranslateService
  ) {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  public sim_status_data:boolean = true;
  public esim_data_visible:string = ''
  public sim_status_data_voice:boolean = false;
  public esim_data_voice_visible: string = 'hidden';
  public dataVoiceEsims:boolean = true;
  public countryName:string = '';
  
  onKey(event: Event) {
    this.filteredCountries = [];
    this.search((event.target as HTMLInputElement).value);
  }

  // Filter the states list and send back to populate the selectedStates
  search(value: string) {
    if (value == '') {
      this.onTypeChange();
    } else {
      let filter = value.toLowerCase();
      for (let i = 0; i < this.tmpCountries.length; i++) {
        let option = this.tmpCountries[i];
        if (option.country.toLowerCase().indexOf(filter) >= 0) {
          this.filteredCountries.push(option);
        }
      }
    }
  }

  toogleEsimSearcher() {
    this.filteredPackages = [];
    this.showSearchEsim = true;
    this.showEsims = false;
    this.selectedCountry = '';
  }

  toggleClassDataVoice() {
    this.sim_status_data_voice = !this.sim_status_data_voice;
    this.esim_data_voice_visible = this.sim_status_data_voice ? '' : 'hidden';
    this.sim_status_data = !this.sim_status_data;
    this.esim_data_visible = this.sim_status_data ? '' : 'hidden';
  }

  toggleClassData() {
    this.sim_status_data = !this.sim_status_data;
    this.esim_data_visible = this.sim_status_data ? '' : 'hidden';
    this.sim_status_data_voice = !this.sim_status_data_voice;
    this.esim_data_voice_visible = this.sim_status_data_voice ? '' : 'hidden';
  }

  async ngOnInit() {
    const usuario = await this.getusuario()

    if (usuario != '') {
      this.getUserEsimList();
      this.IsOnline = true
    }
  }

  async login() {
    if (this.loginForm.invalid) return;
    const formData = this.loginForm.getRawValue();
    if (formData.username && formData.password) {
      try {
        await this.authService.login(formData.username, formData.password);
        this.usuario = await this.getusuario();
        this.getUserEsimList();

        this.router.navigate(['esim'], { replaceUrl: true });
        const language = this.translateService.getBrowserLang() || 'en';
        const user = await Auth.currentAuthenticatedUser();
        this.translateService.use(user.attributes?.['locale'] || language);
      } catch (e: any) {
        this._snackBar.open(e.message, 'Close', { duration: 5000 });
        if (e.code === 'UserNotConfirmedException') {
          this.router.navigate(['/onboarding/validation'], { replaceUrl: true });
        }
      }
    }
  }

  async getusuario() {
    try {

      return this.authService.getUser().username ?? '';
    } catch {
      console.log('error no logueado')
      return ''
    }
  }

  async getfilterPackages(tipo: string): Promise<void> {
    this.showSpinner = true;
    try {
      //const apiPackages = await this.esimService.getPackages();
      const apiPackages = await this.esimService.getfilteredPackages(tipo);

      if (!apiPackages) {
        console.error('Response is undefined or empty');
        return;
      }

      // Parsear la respuesta si es una cadena JSON
      const apiResponse = typeof apiPackages === 'string' ? JSON.parse(apiPackages) : apiPackages;

      // Crear un mapa para organizar paquetes por país
      const packagesMap: { [key: string]: { country: string, image: string, packages: any[] } } = {};
      this.packagesByCountry = [];
      this.filteredPackages = [];

      // Procesar cada paquete
      apiResponse.forEach((pkg: any) => {
        if (pkg.pkg_country_code) {
          // Verificar si el país ya está en el mapa
          if (!packagesMap[pkg.pkg_country_code]) {
            // Crear una nueva entrada en el mapa para este país
            packagesMap[pkg.pkg_country_code] = {
              country: pkg.pkg_country,
              image: pkg.pkg_country_url || 'https://cdn-dev.zendy.tel/images/Promo-Zendy-3-24@3x.png', // Proporciona una ruta por defecto si es necesario
              packages: []
            };

            // Agregar el país a la lista
            this.packagesByCountry.push({
              country: pkg.pkg_country_title,
              code: pkg.pkg_country_code,
              image: pkg.pkg_country_url,
              packages: []
            });
          }

          // Agregar el paquete a la lista de paquetes del país correspondiente
          const countryPackages = packagesMap[pkg.pkg_country_code].packages;

          // Aquí agregamos la lógica del `type` para cada paquete
          const type = pkg.pkg_type || 'Local'; // Usa "Local" como predeterminado si no se encuentra `pkg_type`

          countryPackages.push({
            country: pkg.pkg_country,
            countryimg: packagesMap[pkg.pkg_country_code].image,
            voice: pkg.pkg_voice,
            amount: pkg.pkg_amount,
            short_info: pkg.pkg_short_info,
            data: pkg.pkg_data,
            manual_installation: pkg.pkg_manual_installation,
            net_price: pkg.pkg_price,
            type: type, // Asignar el `type` al paquete
            title: pkg.pkg_title,
            is_unlimited: pkg.pkg_is_unlimited,
            qr_installation: pkg.pkg_qr_installation,
            price: pkg.pkg_price,
            id: pkg.pkg_id,
            text: pkg.pkg_text,
            day: pkg.pkg_day,
            operador: [{
              operador: pkg.operador_title,
              type: pkg.operador_plan_type,
              prepaid: pkg.pkg_is_prepaid === 'true',
              esimtype: pkg.operador_apn_type,
              warning: pkg.operador_warning,
              apn_type: pkg.operador_apn_type,
              apn_value: pkg.operador_apn_value,
              is_roaming: pkg.operador_is_roaming,
              info: pkg.operador_info,
              image: pkg.operador_url || 'https://cdn-dev.zendy.tel/images/Promo-Zendy-3-24@3x.png',
              plan_type: pkg.operador_plan_type,
              activation_policy: pkg.operador_activation_policy,
              is_kyc_verify: pkg.operador_is_kyc_verify,
              rechargeability: pkg.operador_rechargeability,
              other_info: pkg.operador_other_info
            }]
          });
        }
      });

      // Asignar los paquetes a los países en packagesByCountry
      this.packagesByCountry.forEach(pkg => {
        pkg.packages = packagesMap[pkg.code]?.packages || [];
      });

      // Ordenar países alfabéticamente
      this.packagesByCountry.sort((a, b) => a.country.localeCompare(b.country));

      // Llenar la lista por defecto con todos los paquetes
      // console.log('Packages by Country:', this.packagesByCountry);
      // console.log('Filtered Packages:', this.filteredPackages);
      //this.showSpinner = false;
    } catch (error) {
      console.error('Error fetching packages:', error);
    }
    finally{
      this.showSpinner = false;
    }
  }

  async onTypeChange(): Promise<void> {

    this.filteredCountries = [];
    this.tmpCountries = [];

    // si es Local asignar local, si es Regional asignar global
    const type = this.selectedType

    await this.getfilterPackages(type)

    this.isDisabled = this.selectedType == "";

    this.packagesByCountry.forEach(country => {
      const filteredPackages = country.packages.filter(pkg => pkg.type === type);
      
      if (filteredPackages.length > 0) {
        this.filteredCountries.push({
          ...country,
          packages: filteredPackages
        });
      }
    });
    this.tmpCountries = this.filteredCountries;
    this.selectedCountry = '';
    this.filteredPackages = [];
  }

  loadEsimPackages(): void {
    this.showSearchEsim = false;
    this.showEsims = false;
    this.countryName = '';
    if (this.selectedCountry) {
      const selectedCountryData = this.packagesByCountry.find(pkg => pkg.code === this.selectedCountry);
      this.filteredPackages = selectedCountryData ? selectedCountryData.packages : [];
      this.filteredPackages.sort((a, b) => a.price - b.price);

      this.dataVoiceEsims = !this.filteredPackages.some(item => item.operador[0].plan_type === "data-voice-text");
      this.countryName = this.filteredCountries.find(tmpCountry => tmpCountry.code === this.selectedCountry).country;
    } else {
      this._snackBar.open('Please select a country', 'Close', { duration: 3000 });
    }
  }

  showMyEsims(): void {
    this.showSearchEsim = false;
    this.showEsims = true;
    this.filteredPackages = [];
  }

  async getUserEsimList(): Promise<void> {
    this.userEsims = await this.esimService.getEsimList();

    if (!this.userEsims) {
      console.error('Response is undefined or empty');
      return;
    }
  }

  extractCountries(packages: any[]): any[] {
    const countryMap = new Map();

    //console.log(packages);

    packages.forEach(pkg => {
      if (!countryMap.has(pkg.country_code)) {
        countryMap.set(pkg.country_code, pkg.image);
      }
    });
    return Array.from(countryMap.entries()).map(([country_code, image]) => ({ country_code, image }));
  }

  openBuyNowDialog(packageData: any): void {

    const country = this.selectedCountry;
    const operador = packageData.operador[0].operador;

    this.dialog.open(BuyNowModalComponent, {
      width: '650px',
      data: { package: packageData, country: country, operador: operador }
    });
  }

  openTopupsDialog(iccid: string): void {
    const esimDetails = this.userEsims.filter(e => e.iccid == iccid);
    sessionStorage.setItem('esimDetails', JSON.stringify(esimDetails));

    this.router.navigate(['/topup', iccid]);
  }

  openEsimDetailsDialog(iccid: string): void {
    this.router.navigate(['/esim-details'], { state: { data: iccid } });
  }

  capitalizeCountryName(country: string): string {
    return country
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  calculatePercentage(total: number | undefined, remaining: number | undefined): number {
    if (!total || !remaining) {
      return 0;
    }
    return ((total - remaining) / total) * 100;
  }

}
