import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  APIService,
  Call,
  ModelCallConditionInput,
  ModelCallFilterInput,
  ModelIntInput,
  ModelSortDirection,
  ModelStringInput,
  ModelStringKeyConditionInput,
  UpdateCallInput,
} from '../../API.service';
import { AuthService } from '../../services/auth.service';
import { Action, Item, ItemAbstract } from '../../utils/Item';
import {
  ConfirmationComponent,
  ModalData,
} from '../../components/confirmation/confirmation.component';
import { ConfigService } from '../../config.service';
import { CallInfo } from 'src/models/extra';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-history-list',
  template: '<app-list (delete)="onDelete($event)" [hasDelete]="deleteActiveinList" [pageSize]="configService.config.pageSize" [nextToken]="nextToken" [router]="router" [setItemSelected]="setItemSelected" [getItemSelected]="getItemSelected" [items]="items" [itemActions]="itemActions" [go]="go"></app-list>',
  providers: [DatePipe]
})
export class HistoryListComponent extends ItemAbstract implements OnInit {
  public deleteActiveinList: boolean = true;
  public localeLanguage!: string;

  constructor(
    private api: APIService,
    private authService: AuthService,
    public router: Router,
    public configService: ConfigService,
    private dialog: MatDialog,
    private datePipe: DatePipe
  ) {
    super();
    this.itemActions = [
      {
        name: `COMPONENTS.LIST.REDIAL`,
        icon: 'dialpad',
        route: '/calls/dial/',
        arg_index: 0, //Must be in the Cast!
      },
      {
        name: `COMPONENTS.LIST.ADD_CONTACT`,
        icon: 'add',
        route: '/contacts/new-contact/',
        arg_index: 2, //Must be in the Cast!
      },
    ] as Action[];
  }

  async ngOnInit() {
    this.localeLanguage = window.localStorage.getItem('_language') ?? 'en';
    this.fetchItems();
  }

  fetchItems() {
    this.api
      .CallsByOwnerAndCreatedAt(
        this.authService.getUser().username ?? '',
        {} as ModelStringKeyConditionInput,
        'DESC' as ModelSortDirection,
        { visible: { eq: 1 } as ModelIntInput } as ModelCallFilterInput,
        this.router.url.includes('home') ? 5 : 20,
        this.nextToken
      )
      .then((event) => {
        this.nextToken = event.nextToken;
        this.items = this.castToItem(event.items as Call[]) as Item[];
      });
  }

  onDelete(id: string) {
    const data: ModalData = {
      titleKey: 'COMMON.SURE',
      descKey: 'COMMON.SURE_DESC',
      acceptTextKey: 'COMMON.YES',
      cancelTextKey: 'COMMON.NO',
    };
    this.dialog
      .open(ConfirmationComponent, {
        panelClass: 'zendy-modal',
        data,
      })
      .afterClosed()
      .subscribe((confirm: Boolean) => {
        if (confirm) {
          this.delete(id);
        }
      });
  }

  async delete(id: string) {
    await this.api.UpdateCall(
      {
        id: id,
        visible: 0,
      } as UpdateCallInput,
      {
        owner: {
          eq: this.authService.getUser().username ?? '',
        } as ModelStringInput,
      } as ModelCallConditionInput
    );
    await this.fetchItems();
  }

  castToItem(items: any[]) {
    return items.map((i) => {
      const info = Object.assign({}, CallInfo, JSON.parse(i.info))
      const label = info.labelDestinationNumber ? `${info.labelDestinationNumber} (${i.destinationNumber})` : i.destinationNumber
      const destinationUrl = i.destinationNumber + '/' + info.labelDestinationNumber
      const dateObject = (new Date(Date.parse(i.createdAt)))
      dateObject.setTime(dateObject.getTime() + -1 * dateObject.getTimezoneOffset() * 60 * 1000);
      const date = this.datePipe.transform(dateObject.toUTCString(), 'medium', 'UTC', this.localeLanguage)
      const charge = info.labelCharge ? `${parseFloat(info.labelCharge).toFixed(3)} ${info.labelCurrency}` : `0.000 ${info.labelCurrency}`;
      let time_hh = '00:00:00';

      if (info.labelTime === 0) {
        time_hh = '00:00:00';
      } else {

        const hours = Math.floor(info.labelTime / 3600);
        const minutes = Math.floor((info.labelTime % 3600) / 60);
        const secondsRemaining = Math.floor(info.labelTime % 60);

        const hourFormat = hours < 10 ? `0${hours}` : hours.toString();
        const minutesFormat = minutes < 10 ? `0${minutes}` : minutes.toString();
        const secondsFormat = secondsRemaining < 10 ? `0${secondsRemaining}` : secondsRemaining.toString();

        time_hh = `${hourFormat}:${minutesFormat}:${secondsFormat}`;
      }
      return {
        id: i.id,
        icon: 'wifi_calling_3',
        title: label,
        caption: info.labelCharge == 0 ? `${date}` :
          `${date} - ${parseFloat(info.labelTime).toFixed(2)} secs, ${parseFloat(info.labelCharge).toFixed(3)} EUR.`,
        args: [destinationUrl, i.id, i.destinationNumber],

        amount: charge,
        total_time: time_hh,
        call_date: date,
        destination: i.destinationNumber,
      };
    });
  }

  go(route: string, arg_index: number = 0) {
    this.router.navigate([`${route}${this.getItemSelected().args[arg_index]}`]);
  }

  getTime(createdAt: any, updatedAt: any) {
    return this.transform(
      (Date.parse(updatedAt) - Date.parse(createdAt)) / 1000
    );
  }

  getCharge(info: string): number {
    return typeof JSON.parse(info)['charge'] !== 'undefined' &&
      JSON.parse(info)['charge']
      ? JSON.parse(info)['charge']
      : 0.0;
  }

  transform(value: number): string {
    const hours: number = Math.floor(value / 60);
    const minutes: number = value - hours * 60;

    if (hours < 10 && minutes < 10) {
      return '0' + hours + ':0' + (value - hours * 60);
    }
    if (hours > 10 && minutes > 10) {
      return '0' + hours + ':' + (value - hours * 60);
    }
    if (hours > 10 && minutes < 10) {
      return hours + ':0' + (value - hours * 60);
    }
    if (minutes > 10) {
      return '0' + hours + ':' + (value - hours * 60);
    }
    return '00:00';
  }
}
