import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { List, Item, Action } from '../../utils/Item';
import { ItemDetailDialogComponent } from '../item-detail-dialog/item-detail-dialog.component'; // Asegúrate de que la ruta sea correcta

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styles: [
    `
      :host {
        overflow-y: auto;
      }
    `,
  ],
})
export class ListComponent implements List {
  [x: string]: any;

  @Output() public showChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() onClick = new EventEmitter();

  @Input() public hasDetail: boolean = false;

  @Output('detail') public detailChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() public hasDelete: boolean = false;

  @Output('delete') public deleteChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() public hasEdit: boolean = false;

  @Input() public isGroupedBy: boolean = false;

  @Output('edit') public editChange: EventEmitter<string> = new EventEmitter<string>();

  @Output('view') public viewChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() public items: Array<Item> = [];

  @Input() public groupedItems: { [initial: string]: Item[] } = {};

  @Input() public itemSelected: string = '-1';

  @Input() public itemActions: Array<Action> = [];

  @Input() public actions: Array<Action> = [];

  @Input() public nextToken: any = null;

  @Input() public pageSize: number = 10;

  @Input() public setItemSelected(id: string): void {}

  @Input() public getItemSelected(): void {}

  @Input() public go(route: string, arg_index: number = 0): void {}

  @Input() public router: Router | undefined;

  constructor(public dialog: MatDialog) {}

  hideList() {
    this.showChange.emit(false);
  }

  onDetail() {
    this.detailChange.emit(this.itemSelected);
  }

  onDelete() {
    this.deleteChange.emit(this.itemSelected);
  }

  onEdit() {
    this.editChange.emit(this.itemSelected);
  }

  onView() {
    //this.viewChange.emit(this.itemSelected);
    const item = this.items.find((item) => item.id === this.itemSelected);
    //console.log(item);
    if (item) {
      this.dialog.open(ItemDetailDialogComponent, {
        width: '400px',
        data: item,
        panelClass: 'zendy-modal',
      });
    }
  }

  showAddContactAction(action: Action) {
    if (action.route === '/contacts/new-contact/') {
      const item = this.items.find((item) => item.id === this.itemSelected);
      let regularExpression = /\(/;
      if (item && regularExpression.test(item.title)) {
        return false;
      }
      return true;
    } else {
      return true;
    }
  }
}
