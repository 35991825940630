import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '../../components/button/button.module';
import { RouterModule, Routes } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotComponent } from './forgot/forgot.component';
import { EmailCodeValidationComponent } from './email-code-validation/email-code-validation.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageComponent } from 'src/app/components/language/language.component';
import { OnBoardingHeaderComponent } from './onboarding-header/onboarding-header.component';
import { ConditionsComponent } from '../about/conditions/conditions.component'
import { HelpComponent } from '../about/help/help.component';
import { CheckoutNotificationComponent } from '../checkout-notification/checkout-notification.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { EsimComponent } from '../esim/esim.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TuiSortCountriesPipeModule } from '@taiga-ui/kit';
import { FilterCountriesPipe } from '../../components/zendy-intl-phone/filter-countries.pipe';
import { ZendyIntlPhoneComponent } from '../../components/zendy-intl-phone/zendy-intl-phone.component';
import { MatIconModule } from '@angular/material/icon';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'forgot',
    component: ForgotComponent,
  },
  {
    path: 'validation',
    component: EmailCodeValidationComponent,
  },
  {
    path: 'new-password',
    component: NewPasswordComponent,
  },
  {
    path: 'conditions',
    component: ConditionsComponent,
  },
  {
    path: 'helps',
    component: HelpComponent,
  },
  {
    path: 'checkout/ok',
    component: CheckoutNotificationComponent,
  },
  {
    path: 'checkout/ko',
    component: CheckoutNotificationComponent,
  },
  {
    path: 'esim',
    component: EsimComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    ButtonModule,
    RouterModule.forChild(routes),
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    LanguageComponent,
    RecaptchaV3Module,
    FilterCountriesPipe,
    MatIconModule,
    TuiSortCountriesPipeModule,
    ZendyIntlPhoneComponent
  ],
  providers: [
    //   { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6Lf3WrcpAAAAAFUiI11krOrL8CX6KzzaNQ2gN4dp' }
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6Ld89espAAAAAArHePBER5Jo0poZQxmtAPnrpLJa',
    },
  ],
  declarations: [LoginComponent, RegisterComponent, ForgotComponent, EmailCodeValidationComponent, NewPasswordComponent, OnBoardingHeaderComponent],
  exports: [LoginComponent, RegisterComponent, ForgotComponent, EmailCodeValidationComponent, NewPasswordComponent, OnBoardingHeaderComponent],
})
export class OnBoardingModule { }
