<app-onboarding-header></app-onboarding-header>
<form class="md:max-w-xl md:m-auto" [formGroup]="registerForm">
  <div class="flex flex-col items-center p-4">
    <h2>{{ 'ONBOARDING.REGISTER.TITLE' | translate }}</h2>

    <zendy-intl-phone class="w-full rounded-none mb-4"
                      formControlName="username"
                      [countries]="(countries | tuiSortCountries | async) || []"
                      [(countryIsoCode)]="countryIsoCode">
    </zendy-intl-phone>

    <!--
  <mat-form-field class="calls-full-width">
    <mat-label>{{ 'ONBOARDING.REGISTER.USER' | translate }}</mat-label>
    <input type="text" matInput formControlName="username" />
    <mat-error *ngIf="registerForm.get('username')?.hasError('pattern')">
      {{ 'ONBOARDING.REGISTER.WHITE_SPACE' | translate }}
    </mat-error>
  </mat-form-field>
  <mat-form-field class="calls-full-width">
    <mat-label>{{ 'ONBOARDING.REGISTER.EMAIL' | translate }}</mat-label>
    <input type="email" matInput formControlName="email" />
    <mat-error *ngIf="registerForm.get('email')?.hasError('pattern')">
      {{ 'ONBOARDING.REGISTER.WHITE_SPACE' | translate }}
    </mat-error>
  </mat-form-field>
  -->

    <mat-form-field class="calls-full-width">
      <mat-label>{{ 'ONBOARDING.REGISTER.PASS1' | translate }}</mat-label>
      <div class="mt-2">
        <div class="flex items-center rounded-md pl-3 outline-1 -outline-offset-1 outline-gray-300 has-[input:focus-within]:outline-2 has-[input:focus-within]:-outline-offset-2 has-[input:focus-within]:outline-indigo-600">
          <input [type]="mostrarPassword1 ? 'text' : 'password'" matInput formControlName="password" />
          <div class="grid shrink-0 grid-cols-1 focus-within:relative">
            <mat-icon class="material-icons-outlined cursor-pointer" id="showpassword1" (click)="mostrarpass1()" title="{{ 'ONBOARDING.LOGIN.'+showpasstext1 | translate }} ">{{icon1}}</mat-icon>

          </div>
        </div>
      </div>
      <mat-error *ngIf="registerForm.get('password')?.hasError('pattern')">
        {{ 'ONBOARDING.REGISTER.WHITE_SPACE' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="calls-full-width">
      <mat-label>{{ 'ONBOARDING.REGISTER.PASS2' | translate }}</mat-label>
      <div class="mt-2">
        <div class="flex items-center rounded-md pl-3 outline-1 -outline-offset-1 outline-gray-300 has-[input:focus-within]:outline-2 has-[input:focus-within]:-outline-offset-2 has-[input:focus-within]:outline-indigo-600">
          <input [type]="mostrarPassword2 ? 'text' : 'password'" matInput formControlName="password2" />
          <div class="grid shrink-0 grid-cols-1 focus-within:relative">
            <mat-icon class="material-icons-outlined cursor-pointer" id="showpassword2" (click)="mostrarpass2()" title="{{ 'ONBOARDING.LOGIN.'+showpasstext2 | translate }} ">{{icon2}}</mat-icon>

          </div>
        </div>
      </div>
      <mat-error *ngIf="registerForm.get('password2')?.hasError('pattern')">
        {{ 'ONBOARDING.REGISTER.WHITE_SPACE' | translate }}
      </mat-error>
    </mat-form-field>

    <div class="check-full-width">
      <mat-label for="acceptTermsCheckbox">
        <input type="checkbox" id="acceptTermsCheckbox" (click)="toggleTerms()">
        {{ 'ONBOARDING.REGISTER.TERMS_START' | translate }} <a target='_blank' href='https://zendy.tel/privacy'>{{ 'ONBOARDING.REGISTER.TERMS_PRIVACY' | translate }}</a> {{ 'ONBOARDING.REGISTER.TERMS_AND' | translate }} <a target='_blank' href='https://zendy.tel/terms'>{{ 'ONBOARDING.REGISTER.TERMS_TERMS' | translate }}</a>
      </mat-label>
    </div>

    <p class="pb-4"></p>
    <app-button (click)="signUp()" [disabled]="!acceptTermsControl">
      {{ 'ONBOARDING.REGISTER.BUTTON' | translate }}
    </app-button>

  </div>
  <div class="p-4">
    <hr />
  </div>
  <div class="flex flex-col items-center p-4">
    <p class="text-sm mb-8 font-medium">
      {{ 'ONBOARDING.REGISTER.QUESTION' | translate }}
    </p>
    <app-button color="secondary" routerLink="/onboarding/login" [replaceUrl]="true">
      {{ 'ONBOARDING.REGISTER.GO_BACK' | translate }}
    </app-button>
  </div>
</form>

<div class="loading-mask" *ngIf="showSpinner">
  <mat-spinner diameter="50" strokeWidth="3"></mat-spinner>
</div>

<style>
  .mat-mdc-form-field-error {
    color: red;
}
</style>
