import { Component, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { OnboardingService } from '../onboarding.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
})
export class ForgotComponent {
  username!: string;
  _snackBar = inject(MatSnackBar);
  router = inject(Router);
  onboardingService = inject(OnboardingService);
  authService = inject(AuthService);

  async resetPassWord() {
    if (this.username) {
      this.onboardingService.setUsername(this.username);
      return await this.authService
        .resetPassword(this.username)
        .then((e) => {
          this._snackBar.open('Code sended to your Phonenumber', 'Close', {
            duration: 5000,
          });
          this.router.navigate(['/onboarding/new-password'], {
            replaceUrl: true,
          });
        })
        .catch((error) => {
          this._snackBar.open(error.message, 'Close', {
            duration: 5000,
          });
        });
    }
  }
}
