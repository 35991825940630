<div class="container my-4">
  <div class="loading-mask" *ngIf="showSpinner">
    <mat-spinner diameter="50" strokeWidth="3"></mat-spinner>
  </div>

  <div class="bg-danger text-white rounded-lg p-4">
    <img alt="banner" class="rounded-lg mb-4" height="100"
         src="./assets/icons/EsimHeader.jpg" width="100%" />
    <p class="text-xl font-bold text-black">{{ 'ESIM.TOPUP_TITLE_1' | translate }}</p>
    <p class="mt-2 text-black">{{ 'ESIM.TOPUP_TITLE_2' | translate }}</p>
  </div>

  <div *ngIf="esimDetails" class="grid grid-cols-1 gap-4 mx-auto">
    <mat-card class="esim-card" appearance="outlined">

      <mat-card-header class="mb-2 flex justify-center items-top">
        <div class="absolute left-0 ml-4 p-2 flex" style="height:fit-content;">
          <div *ngFor="let item of esimDetails.pkg_details.operator_info.plan_type.split('-')">
            <img width="16" [src]="'./assets/icons/' + item + '_ico.svg'" title="{{ item }}" class="mx-1" />
          </div>
        </div>
        <div class="rounded-full bg-white absolute right-0 mr-4 p-2" style="height:fit-content;">
          <span class=" flag large block content-center"><img src="{{ esimDetails.pkg_details.country_url }}" /></span>
        </div>

        <div class="esim-my-card-image-container relative ml-4">
          <img src="https://cdn-dev.zendy.tel/images/Esim/EsimCard@2x.png" alt="SIM Card" class="esim-my-card-image" />
          <span class="esim-my-country-label">{{ esimDetails.country }}</span> <!-- Nombre del país en la imagen -->
        </div>
      </mat-card-header>

      <mat-card-content>
        <mat-card-subtitle class="font-bold text-center">{{ esimDetails.details.iccid }}</mat-card-subtitle>
        <mat-card-title class="font-bold text-center">{{ esimDetails.country | titlecase }}</mat-card-title>
        <mat-card-subtitle class="text-center mb-3"><span class="font-bold">{{ "ESIM.PURCHASE_DETAILS.VALIDITY" | translate }}:</span> {{ esimDetails.validity }}</mat-card-subtitle>

        <!-- Datos -->
        <div class="progress-section" *ngIf="(esimDetails.usage_data?.total != 0)">
          <div class="label-container">
            <strong>{{ 'ESIM.DATA' | translate }}:</strong>
            <p class="item-value right-align">
              {{ 'ESIM.USED' | translate }}: {{ esimDetails.usage_data?.total ? esimDetails.usage_data.total - esimDetails.usage_data.remaining : 0 }}MB
              {{ 'ESIM.OF' | translate }} {{ esimDetails.usage_data?.total ? esimDetails.usage_data.total : 0 }}MB
            </p>
          </div>
          <mat-progress-bar mode="determinate"
                            [value]="calculatePercentage(esimDetails.usage_data?.total, esimDetails.usage_data?.remaining)"
                            class="progress-bar"></mat-progress-bar>
        </div>

        <!-- Voz -->
        <div class="progress-section" *ngIf="(esimDetails.usage_data?.total_voice != 0)">
          <div class="label-container">
            <strong>{{ 'ESIM.VOICE' | translate }}:</strong>
            <p class="item-value right-align">
              {{ 'ESIM.USED' | translate }}: {{ esimDetails.usage_data?.total_voice ? esimDetails.usage_data.total_voice - esimDetails.usage_data.remaining_voice : 0 }}
              min {{ 'ESIM.OF' | translate }} {{ esimDetails.usage_data?.total_voice ? esimDetails.usage_data.total_voice : 0 }} min
            </p>
          </div>
          <mat-progress-bar mode="determinate"
                            [value]="calculatePercentage(esimDetails.usage_data?.total_voice, esimDetails.usage_data?.remaining_voice)"
                            class="progress-bar"></mat-progress-bar>
        </div>

        <!-- SMS -->
        <div class="progress-section" *ngIf="(esimDetails.usage_data?.total_text != 0)">
          <div class="label-container">
            <strong>SMS:</strong>
            <p class="item-value right-align">
              {{ 'ESIM.USED' | translate }}: {{ esimDetails.usage_data?.total_text ? esimDetails.usage_data.total_text - esimDetails.usage_data.remaining_text : 0 }}
              {{ 'ESIM.OF' | translate }} {{ esimDetails.usage_data?.total_text ? esimDetails.usage_data.total_text : 0 }}
            </p>
          </div>
          <mat-progress-bar mode="determinate"
                            [value]="calculatePercentage(esimDetails.usage_data?.total_text, esimDetails.usage_data?.remaining_text)"
                            class="progress-bar"></mat-progress-bar>
        </div>
      </mat-card-content>

    </mat-card>
  </div>

  <div class="grid my-6 !gap-2">
    <h2 class="text-xl font-bold text-center">
      <mat-icon (click)="goBack()" class="float-left ms-4 cursor-pointer">arrow_back_ios</mat-icon>{{ 'ESIM.TOPUPS_AVAILABLE' | translate }}
    </h2>
  </div>

  <div *ngIf="topups.length > 0" class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
    <mat-card *ngFor="let topup of topups" class="p-4 esim-card {{ topups.length > 1 ? '' : 'esim-card-single' }}" appearance="outlined">
      <mat-card-header class="flex items-start">
        <div class="header-image-container text-center w-full">
          <img mat-card-image src="https://cdn-dev.zendy.tel/images/Esim/EsimCard@2x.png" alt="{{ topup.operator }}" class="operator-image">
        </div>
        <div class="w-auto">
          <h2 class="m-0 text-3xl font-bold">{{ topup.price | currency:'EUR':'symbol':'1.2-2':'es-ES' }}</h2>
          <div class="flex" style="height:fit-content;">
            <mat-icon class="text-red-700">autorenew</mat-icon>
          </div>
        </div>
      </mat-card-header>

      <h2 class="text-center text-black m-0 mt-4 text-xl">{{ topup.title }}</h2>
      <hr class="my-2">

      <mat-card-content>
        <!--
        <div class="item-detail flex items-center mb-1">
          <mat-icon class="text-red-700">public</mat-icon>
          <label class="item-label font-bold mx-1">{{ 'ESIM.COVERAGE' | translate }}: </label>
          <span class="item-value">{{ topup.country | titlecase }}</span>
        </div>
        -->
        <div class="item-detail flex items-center my-1">
          <mat-icon class="text-red-700">swap_horiz</mat-icon>
          <label class="item-label font-bold mx-1">{{ 'ESIM.DATA' | translate }}: </label>
          <span class="item-value">{{ topup.data }}</span>
        </div>

        <div class="item-detail flex items-center my-1">
          <mat-icon class="text-red-700">sim_card</mat-icon>
          <label class="item-label font-bold mx-1">{{ 'ESIM.TYPE' | translate }}: </label>
          <span class="item-value">{{ "ESIM."+topup.type | uppercase | translate }}</span>
        </div>
        <div class="item-detail flex items-center mt-1">
          <mat-icon class="text-red-700">date_range</mat-icon>
          <label class="item-label font-bold mx-1">{{ 'ESIM.VALIDITY' | translate }}: </label>
          <span class="item-value">{{ topup.day }} {{ topup.day > 1 ? ('ESIM.DAYS' | translate) : ('ESIM.DAY' | translate) }}</span>
        </div>

      </mat-card-content>

      <mat-card-actions>
        <div class="buybutton mt-4 mx-auto">
          <button mat-button class="buy-button" (click)="sendToProfile ? openProfileModal() : submitTopUp(topup.id)">{{ 'ESIM.RECHARGE_NOW' | translate }}</button>
        </div>
      </mat-card-actions>
    </mat-card>

  </div>

  <div *ngIf="topups.length === 0">
    <p>{{ 'ESIM.NO_TOPUPS_AVAILABLE' | translate }}</p>
  </div>
</div>
